import React from 'react';

import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import { Menu } from '@mui/icons-material';
import logo from '../../src/assets/img/logo.png';
const drawerWidth = 240;
//const navItems = ['Services', 'Benefits', 'Contact'];

const navButtons = (
  <Box display="flex" flexDirection="row">
    <Box sx={{ display: { xs: 'none', sm: 'flex', flexShrink: '1' } }}>
      <HashLink smooth to="/#home" style={{ textDecoration: 'none' }}>
        <Button
          //href="#home"
          color="themeGreenLight"
          sx={{ margin: '5px 20px' }}
        >
          Home
        </Button>
      </HashLink>

      <HashLink smooth to="/#services" style={{ textDecoration: 'none' }}>
        <Button
          //href="#services"
          color="themeGreenLight"
          sx={{ margin: '5px 20px' }}
        >
          Services
        </Button>
      </HashLink>
      <HashLink smooth to="/#benefits" style={{ textDecoration: 'none' }}>
        <Button
          //href="#benefits"
          color="themeGreenLight"
          sx={{ margin: '5px 20px' }}
        >
          Benefits
        </Button>
      </HashLink>
      <HashLink smooth to="/#contact" style={{ textDecoration: 'none' }}>
        <Button
          //href="#contact"
          color="themeGreenLight"
          sx={{ margin: '5px 20px' }}
        >
          Contact
        </Button>
      </HashLink>
      <Button color="themeYellowLight" variant="contained">
        Login
      </Button>
    </Box>
  </Box>
);

function NavbarWithDrawer() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  function toggleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  return (
    <Box>
      <AppBar
        sx={{ margin: '0px', position: 'fixed', height: '60px' }}
        color=""
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            <HashLink smooth to="/#home">
              <img src={logo} alt="logo big" height="60px" />
            </HashLink>
          </Box>
          <Box
            display="flex"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}
          >
            <HashLink smooth to="/#home">
              <img src={logo} alt="logo small" height="40px" />
            </HashLink>
          </Box>
          {navButtons}
          <IconButton
            color="inherit"
            onClick={toggleDrawer}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={toggleDrawer}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Typography variant="h6" margin="12px 0px" textAlign="center">
            FundStar
          </Typography>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton href="/#home" onClick={toggleDrawer}>
                <ListItemText primary="Home" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/#services" onClick={toggleDrawer}>
                <ListItemText primary="Services" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/#benefits" onClick={toggleDrawer}>
                <ListItemText primary="Benefits" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/#contact" onClick={toggleDrawer}>
                <ListItemText primary="Contact" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}

export default NavbarWithDrawer;
