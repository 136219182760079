import { Box } from '@mui/material';
import React from 'react';
import hero from '../../src/assets/img/hero.jpg';
function Hero() {
  return (
    <Box
      id="home"
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '60px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
        component="img"
        src={hero}
      ></Box>
    </Box>
  );
}

export default Hero;
