import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  colors,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import services_asset_allocation from '../../src/assets/img/services_asset_allocation.png';
import services_pe_based_model from '../../src/assets/img/services_pe_based_model.png';
import services_asset_selection from '../../src/assets/img/services_asset_selection.png';
import services_tailor_made from '../../src/assets/img/services_tailor_made.png';

const mock = [
  {
    title: 'Asset Allocation Strategy',
    description:
      'A well-diversified plan will not outperform the top asset class in any given year, but over time it may be one of the most effective ways to realize your long-term goals.',
    illustration: services_asset_allocation,

    color: colors.blue[200],
  },
  {
    title: 'PE Based Model',
    description:
      'The price/earnings ratio (P/E) is the best investment valuation indicator. Our focus is to invest more in equity PE is low and vice versa.',
    illustration: services_pe_based_model,
    color: colors.purple[200],
  },
  {
    title: 'Asset Selection Strategy',
    description:
      'Our focus is to actively manage all asset classes to get best rewards while taking minimal risk.',
    illustration: services_asset_selection,

    color: colors.indigo[200],
  },
  {
    title: 'Tailor Made Investing',
    description:
      'Our portfolio recommendations are according to investor risk profile, time horizon of investment & outflows required at different stages of life-cvcle.',
    illustration: services_tailor_made,
    color: colors.green[200],
  },
];

function Services() {
  return (
    <Box paddingBottom="100px" id="services">
      <Container maxWidth="lg">
        <Box sx={{ pt: 10, pb: 5 }}>
          <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
            Our Services
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: 'text.secondary', mt: 2 }}
          >
            We provide a wide range of services to our clients
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={6}>
            {mock.map((item, i) => (
              <Grid key={i} item xs={12} md={6}>
                <Box
                  component={Card}
                  height={1}
                  bgcolor={alpha(item.color, 0.2)}
                  boxShadow={0}
                  sx={{
                    transform:
                      i % 2 === 1 ? { md: 'translateY(80px)' } : 'none',
                  }}
                >
                  <CardContent sx={{ padding: 4 }}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      marginBottom={{ xs: 2, md: 4 }}
                    >
                      <Box
                        component={'img'}
                        src={item.illustration}
                        width="200px"
                        maxWidth={{ xs: '80%', md: '70%' }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant={'h6'}
                        align="center"
                        gutterBottom
                        sx={{ fontWeight: 700 }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Services;
