import React from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import expertise from '../../src/assets/img/cta_expertise.png';
function Contact() {
  return (
    <Box id="contact">
      <Container sx={{ marginTop: '50px' }}>
        <Divider sx={{ marginBottom: '50px' }} />
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box>
              <Box marginBottom={2}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Talk to our experts.
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Typography variant="h1" color="themeGreenLight.main">
                  1800-890-0244
                </Typography>
              </Box>
              <Box marginBottom={2}>
                <Typography variant="body1">
                  Got a question? Call us! <br />
                  Our relationship and client managers are available from 8 AM
                  to 8 PM to help you.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <Box
              height={1}
              width={1}
              maxWidth="250px"
              display={'flex'}
              justifyContent={'center'}
            >
              <Box
                height={1}
                width={1}
                maxWidth={450}
                component="img"
                src={expertise}
              ></Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contact;
