import React from 'react';
import { Box, Typography } from '@mui/material';
import compounding from '../../src/assets/img/compounding.png';

function Compounding() {
  return (
    <Box sx={{ backgroundColor: 'background.paper', mt: '40px' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
          Power of Compounding
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ color: 'text.secondary', mt: 2 }}
        >
          Illustration for Rs 50,000 invested for 5, 10, 20 and 40 years at 15%
          p.a. compounded annually
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
          component="img"
          src={compounding}
          alt="compounding"
        />
      </Box>
    </Box>
  );
}

export default Compounding;
