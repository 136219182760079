import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';

const mock = [
  {
    title: '1500+',
    subtitle: 'Customers trust us to manage their money.',
    suffix: '+',
  },
  {
    title: '15 Years',
    subtitle: 'Helping our customers to achieve their financial goals.',
    suffix: '+',
  },
  {
    title: '5⭐',
    subtitle: '99% of our customers rated us 5-star.',
    suffix: '%',
  },
];

function Stats() {
  return (
    <Container sx={{ marginTop: '20px' }}>
      <Card>
        <CardContent
          sx={{
            padding: 4,
            '&:last-child': { padding: 4 },
          }}
        >
          <Box>
            <Typography
              variant={'h3'}
              sx={{ fontWeight: 700 }}
              align={'center'}
              gutterBottom
            >
              We thrive on trust.
            </Typography>
            <Typography
              variant={'body1'}
              align={'center'}
              color={'text.secondary'}
            >
              And you don't need to just take our word for it. We manage...
            </Typography>
          </Box>
          <Box marginY={4}>
            <Grid container spacing={2}>
              {mock.map((item, i) => (
                <Grid key={i} item xs={12} md={4}>
                  <Typography
                    variant="h2"
                    align={'center'}
                    fontWeight={700}
                    color="themeGreenLight.main"
                    gutterBottom
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align={'center'}
                    component="p"
                  >
                    {item.subtitle}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Stats;
