import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import benefits_advise from '../../src/assets/img/benefits_advise.png';
import benefits_security from '../../src/assets/img/benefits_security.png';
import benefits_goals from '../../src/assets/img/benefits_goals.png';
import benefits_redeem from '../../src/assets/img/benefits_redeem.png';

const data = [
  {
    title: 'Unbiased Advise',
    subtitle:
      'Our top priority is to provide unbiased advice to our clients. We remain transparent throughout the process, ensuring our clients make informed decisions.',
    image: benefits_advise,
  },
  {
    title: 'Data Security',
    subtitle:
      'We prioritize data security to protect our clients sensitive information. We utilize advanced encryption and data protection measures to secure our clients data from unauthorized access, theft, or loss.',
    image: benefits_security,
  },
  {
    title: 'Goals Driven',
    subtitle:
      'We create personalized investment plans that align with their goals,  whether it is saving for retirement, funding education, or building wealth taking into consideration their risk tolerance, time horizon, and other factors.',
    image: benefits_goals,
  },
  {
    title: 'Book Profits',
    subtitle:
      'Our team of experts monitor the markets and analyze investment opportunities, enabling our clients to book profits at the right time. We create investment strategies that balance risk and reward',
    image: benefits_redeem,
  },
];
function Benefits() {
  return (
    <Box id="benefits">
      <Container maxWidth="lg">
        <Box sx={{ pt: 10, pb: 5 }}>
          <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
            Benefits with us
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: 'text.secondary', mt: 2 }}
          >
            Our approach is centered around helping our clients achieve
            long-term financial success.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {data.map((item, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={'center'}
                alignItems="center"
                marginBottom={{ xs: 2, md: 4 }}
              >
                <Box component="img" src={item.image} width="250px"></Box>
                <Box>
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 700, marginTop: '20px' }}
                    align="center"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Benefits;
